import { animate, group, query, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  ChangeDetectionStrategy,
  viewChild,
  effect,
  input,
  signal,
} from '@angular/core';

const expandCollapse = trigger('expandCollapse', [
  transition('void <=> *', []),
  transition(
    '* <=> *',
    [
      group([
        style({ height: '{{startHeight}}px' }),
        query(':enter', [style({ opacity: '0' }), animate('.15s ease-out')], { optional: true }),
        query(':leave', [animate('.15s ease-out', style({ opacity: '0' }))], { optional: true }),
        animate('.15s ease-in-out'),
      ]),
    ],
    { params: { startHeight: '0px' } },
  ),
]);

@Component({
  selector: 'app-expand-collapse',
  imports: [],
  template: `
    <div
      #contentRef
      [@expandCollapse]="{
        value: isExpanded(),
        params: { startHeight: heightOfContent() },
      }"
      class="content-wrapper"
      [class.collapsed]="!isExpanded()"
    >
      @if (isExpanded()) {
        <div data-testid="content">
          <ng-content></ng-content>
        </div>
      }
    </div>
  `,
  styles: [
    `
      .collapsed {
        height: 0;
      }
      .content-wrapper {
        overflow-y: clip;
        overflow-x: visible;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse],
})
export class ExpandCollapseComponent {
  readonly contentRef = viewChild.required<ElementRef>('contentRef');
  protected heightOfContent = signal(0);
  readonly isExpanded = input.required<boolean>();

  constructor() {
    effect(() => {
      // Trigger a new height on every change
      if (this.isExpanded() || !this.isExpanded())
        this.heightOfContent.set(this.contentRef().nativeElement.clientHeight);
    });
  }
}
